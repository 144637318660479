import React from "react";
import Homepage from "./pages/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/services" element={<Homepage/>} />
        <Route path="/our-work" element={<Homepage/>} />
        <Route path="/why-cipher" element={<Homepage/>} />
        <Route path="/faqs" element={<Homepage/>} />
        <Route path="/contact-us" element={<Homepage/>} />
      </Routes>
    
    </BrowserRouter>
  );
}

export default App;
