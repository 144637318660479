import React from "react"
import design from "../images/design.png"
import dev from "../images/dev.png"
import brand from "../images/branding.png"

export default function Services () {
  return(
    <div className="section pt-24 3xl:max-w-[1595px] m-auto lg:px-20 px-6">
    <div className="flex items-center">
    <div className="w-10 h-2 bg-line mt-1"></div> 
    <div className="service-text pl-2 text-text font-semibold text-xl">Services</div>
    </div>
    <div className="lg:flex pt-8">
      <h1 className="text-6xl tracking-[-3px] font-bold lg:leading-9 w-full pl-3 lg:pl-0">We’re A Full Cycle Design Agency<span className="text-text text-9xl leading-9 mb-2 pl-2">.</span></h1>
        <p className="text-base opacity-60 lg:w-7/12 pt-10 pl-4 lg:pl-0">At cipher we specialize in converting your ideas into products your users love, help your business stand out from competitors and ultimately make more money</p>
    </div>
    <div className="grid lg:grid-cols-3 lg:pt-20 pt-10 pl-4 lg:pl-0">
        <div className="flex">
            <img src={design}/>
            <h3 className="font-bold text-2xl pl-3 pt-3">UI/UX Design</h3>
        </div>
        <div className="flex pt-3 lg:pt-0">
            <img src={dev} />
            <h3 className="font-bold text-2xl pl-3 pt-3">Development</h3>
        </div>
        <div className="flex pt-3 lg:pt-0">
            <img src={brand} />
            <h3 className="font-bold text-2xl pl-3 pt-3">Branding</h3>
        </div>
    </div>
    <div className="grid lg:grid-cols-3 pt-6 pl-4 lg:pl-0">
        <div>
            <p className="font-bold text-base">Mobile App Design</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Website Design</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Dashboard Design</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Design Systems</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Prototyping</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
        </div>
        <div>
            <p className="font-bold text-base">App Development</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Web Development</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">No-Code Development</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">CMS Development</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Software Development</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
        </div>
        <div>
            <p className="font-bold text-base">Logo Design</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Brand Guides</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Packaging Design</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Pitch Decks</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
            <p className="font-bold text-base">Brand Strategy</p>
            <div className="bg-line2 w-9/12 h-2.5 my-3"></div>
        </div>
    </div>
    </div>
  )

}
