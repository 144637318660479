import React from "react"
import Nav from "./Nav"
import emoji from "../images/emoji.svg"
import caseIcon from "../images/briefcase-icon.svg"
import squareIcon from "../images/rectangle.svg"
import PrimaryBtn from "./PrimaryBtn"
export default function Hero () {
    return (
        <div id="noise" className="h-[99vh] max-h-[800px] 3xl:max-h-[800px] bg-blend-multiply bg-hero-texture justify-between lg:pt-8 pt-4 flex-col flex items-center bg-cover bg-center text-white">
        <Nav />
        <div className="section 3xl:max-w-[1595px] px-5 md:px-10 lg:px-20 h-[90%] justify-center lg:pt-16  flex flex-col lg:gap-20 gap-8 w-full ">
            <div className="flex gap-1 flex-col">
                <p className="flex items-center gap-3 text-sm lg:text-base"> <img src={emoji} alt="" /> MORE THAN JUST DESIGN</p>
                <h1 className=" leading-[1.1] tracking-[-2px] lg:tracking-[-3px] 2xl:text-[85px] lg:text-[70px] text-[55px] font-bold capitalize lg:w-4/5">Design your <span className="relative mr-2.5 ml-2.5"><img className="absolute w-[395px] top-[23%] scale-[1.18] left-0 " src={squareIcon} alt="" /> success</span> with limitless creativity</h1>
                <p>At Cipher we help you make more money through our premium design services.</p>
            </div>
            <div className="flex gap-6  text-base lg:text-xl">
                <PrimaryBtn>Book A Call</PrimaryBtn>
                <button className="flex items-center gap-2"><img src={caseIcon} alt="" /> <span className=" border-b">View our Portfolio</span></button>
            </div>
        </div>
        
        </div>
    )
}