import React from "react"
import Hero from "../components/Hero"
import Services from "../components/Services"

export default function Homepage () {
    return (
        <div className=" flex flex-col justify-center w-full">
            <Hero />
            <Services />
        </div>
    )
}