import React from "react"
import Logo from "../images/logo-white.svg"
import { NavLink, Link } from "react-router-dom"
export default function Nav () {
    return (
        <nav className="section px-5 3xl:max-w-[1595px] md:px-10 lg:px-20 flex flex-row w-full items-center justify-between">
            <Link to="/"><img src={Logo} alt="Cipher Design Agency Logo" /></Link>
            <div className="gap-8 flex items-center ">
                <NavLink to="/services" className="lg:block hidden">Services</NavLink>
                <NavLink to="/why-cipher" className="lg:block hidden">Why Cipher?</NavLink>
                <NavLink to="/our-work" className="lg:block hidden">Our Work</NavLink>
                <NavLink to="/faqs" className="lg:block hidden">FAQs</NavLink>
            </div>
            <div>
                <NavLink to="/contact-us">
                <button className="lg:py-3 text-sm lg:text-base lg:px-8 xl:px-10 py-[6px] px-[18px] rounded-xl border-2">
                    Contact Us
                </button>
                </NavLink>
            </div>
        </nav>
    )
}